import React from 'react'

export class AdminFooter extends React.Component {

    
    render() {
        return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            © {new Date().getFullYear()}{" "}&nbsp;
                            <a
                                href="https://xarosa.online"
                                className="text-blueGrey font-semibold py-1"
                            >
                                Xarosa Inc.
                            </a>
                        </div>                        
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <div className="logo">
                                <span className="kai">X</span>-Connect
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        );
    }
}