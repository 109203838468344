import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { userActions, alertActions } from 'utils/actions';
import validator from 'validator';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            password: '',
            passcheck: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { password, passcheck } = this.state;

        let pass_help = '';
        let check_help = '';

        let go = true;

        if (validator.isEmpty(password)) {
            pass_help = 'Password required';
            go = false;
        } else if (! validator.isAlphanumeric(password)) {
            pass_help = 'Only alphanumeric characters allowed in password.';
            go = false;
        } else if (! validator.isLength(password, {min: 8})) {
            pass_help = 'Password too short. (min 8 chars)';
            go = false;
        } else if (password !== passcheck) {
            check_help = 'Passwords do not match.';
            go = false;
        }
  
        if (go) {                                     
            const { code } = this.props.match.params;
            this.props.resetPassword(code, password);
        } else {
            if (pass_help) {
                this.props.showError(pass_help);
            } else if (check_help) {
                this.props.showError(check_help);
            }
        }
    }

    render() {
        const { password, passcheck } = this.state;

        return (
            <>
            <div className="container mx-auto px-4 h-full">            
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <Link
                            to="/"
                            className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                        >
                            <span className="kai">X</span>-Connect                
                        </Link>
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                <div className="text-blueGray-600 text-center mt-6 mb-3 font-bold">
                                    <h2>Password Reset</h2>                                    
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="password"                                            
                                        > 
                                            Enter new Password
                                        </label>
                                        <input
                                            name="password" value={password} onChange={this.handleChange}
                                            type="password"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Password"
                                            required
                                        />                                        
                                    </div>                              
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="passcheck"                                            
                                        > 
                                            Reenter Password
                                        </label>
                                        <input
                                            name="passcheck" value={passcheck} onChange={this.handleChange}
                                            type="passcheck"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Password confirm"
                                            required
                                        />                                        
                                    </div>                              
      
      
                                    <div className="text-center mt-6">
                                        <button
                                            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-6 relative">
                            <div className="w-1/2">
                                <a
                                    href="login"                                  
                                    className="text-emerald-400"
                                >
                                    <small>Login</small>
                                </a>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
          </>  
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    resetPassword: userActions.resetPassword, 
    showError: alertActions.error,     
};

const connectedPage = connect(mapState, actionCreators)(ResetPassword);
export { connectedPage as ResetPassword };