/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { userActions } from 'utils/actions';

// components
import { IndexDropdown } from "components";

class Navbar extends React.Component {

  constructor(props) {
    
    super(props);        
    this.state = {
        navBarOpen: false,        
    };  

  }

  render() {  
    const { navBarOpen } = this.state;

    return (
      <>
        <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link
                to="/"
                className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              >
                  <span className="kai">X</span>-Connect                
              </Link>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => this.setState({ navBarOpen : !navBarOpen})}
              >
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
                (navBarOpen ? " block" : " hidden")
              }
              id="navbarItems"
            >
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center">
                  <IndexDropdown />
                </li>         
                <li className="flex items-center">
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="#logout"                    
                    onClick={() => this.props.logout()}
                  >
                    <i className="text-blueGray-400 fas fa-sign-out-alt text-lg leading-lg "  title="Exit χ-Connect"/>
                    <span className="lg:hidden inline-block ml-2">Logout</span>
                  </a>
                </li>     
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}


function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { authentication, user };
}

const actionCreators = {
  logout: userActions.logout,
};

const connectedComponent = connect(mapState, actionCreators)(Navbar);
export { connectedComponent as Navbar };