import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from 'utils/helpers';
import { alertActions } from 'utils/actions';
import { PrivateRoute } from 'utils/components';
import { Landing } from 'pages/top';
import { Auth, Admin } from 'layouts';

import { AlertPopup } from 'components';

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    render() {
        const { alert } = this.props;
        return (
            <div className="container max-w-full min-h-screen absolute top-0 left-0">
                <AlertPopup 
                        show={alert}
                        msgContent={alert.message}
                        closePopup={() => {this.props.clearAlerts()}}
                ></AlertPopup>    
                <Router history={history}>
                    <Switch>          
                        {/* add routes with layouts */}
                        <Route path="/auth" component={Auth} />                  
                        <PrivateRoute path="/admin" component={Admin} />                
                        {/* add routes without layouts */}
                        <PrivateRoute exact path="/" component={Landing} />                        
                        {/* add redirect for first page */}
                        <Redirect from="*" to="/" />
                    </Switch>                    
                </Router>                             
            </div>
        );
    }
}


function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };