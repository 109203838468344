import React from 'react'

export class AdminTitleBar extends React.Component {


    render() {
        return (
            <section className="is-title-bar">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <ul>
                                <li>Admin</li>
                                <li>Dashboard</li>
                            </ul>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">                
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}