import React from 'react'

import { connect } from 'react-redux';
import { userActions } from 'utils/actions';

import { history } from 'utils/helpers';

class AdminNavBar extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            show_popup: this.props.show,
            ok : (this.props.okLabel) ? this.props.okLabel : 'OK',            
        }
        
        this.toggleBurger = this.toggleBurger.bind(this);                
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }


    toggleBurger(e) {
        const dropdownIcon = e.currentTarget.getElementsByClassName('icon')[0].getElementsByClassName('mdi')[0];
        
        document.documentElement.classList.toggle('aside-mobile-expanded');
        dropdownIcon.classList.toggle('mdi-forwardburger');
        dropdownIcon.classList.toggle('mdi-backburger');    
    }

    
    toggleDropdown(e) {
        /* Aside & Navbar: dropdowns */
        if (e.currentTarget.classList.contains('navbar-item')) {
            e.currentTarget.classList.toggle('active');
        } else {
            const dropdownIcon = e.currentTarget.getElementsByClassName('mdi')[1];
            e.currentTarget.parentNode.classList.toggle('active');
            dropdownIcon.classList.toggle('mdi-plus');
            dropdownIcon.classList.toggle('mdi-minus');
        }
    }
    
    render() {
        return (
        <nav id="navbar-main" className="navbar is-fixed-top">
            <div className="navbar-brand">
                <button className="navbar-item mobile-aside-button" onClick={this.toggleBurger}>
                    <span className="icon"><i className="mdi mdi-forwardburger mdi-24px"></i></span>
                </button>
                <div className="navbar-item">
                    <div className="control"><input placeholder="Search everywhere..." className="input" /></div>
                </div>
            </div>
            <div className="navbar-brand is-right">
                <a href="#xarosa" className="navbar-item --jb-navbar-menu-toggle" data-target="navbar-menu">
                    <span className="icon"><i className="mdi mdi-dots-vertical mdi-24px"></i></span>
                </a>
            </div>
            <div className="navbar-menu" id="navbar-menu">
                <div className="navbar-end">
                    <div className="navbar-item dropdown has-divider" onClick={this.toggleDropdown}>
                        <div className="navbar-link">
                            <span className="icon"><i className="mdi mdi-menu"></i></span>
                            <span>Sample Menu</span>
                            <span className="icon">
                                <i className="mdi mdi-chevron-down"></i>
                            </span>
                        </div>
                        <div className="navbar-dropdown">
                            <a href="profile.html" className="navbar-item">
                                <span className="icon"><i className="mdi mdi-account"></i></span>
                                <span>My Profile</span>
                            </a>
                            <a href="#xarosa" className="navbar-item">
                                <span className="icon"><i className="mdi mdi-settings"></i></span>
                                <span>Settings</span>
                            </a>
                            <a href="#xarosa" className="navbar-item">
                                <span className="icon"><i className="mdi mdi-email"></i></span>
                                <span>Messages</span>
                            </a>
                            <hr className="navbar-divider" />
                            <button className="navbar-item" onClick={() => history.push('/auth/login') }>
                                <span className="icon"><i className="mdi mdi-logout"></i></span>
                                <span>Log Out</span>
                            </button>
                        </div>
                    </div>
                    <div className="navbar-item dropdown has-divider has-user-avatar" onClick={this.toggleDropdown}>
                        <a href="#xarosa" className="navbar-link">
                            <div className="user-avatar">
                                <img src="https://avatars.dicebear.com/v2/initials/john-doe.svg" alt="John Doe" className="rounded-full" />
                            </div>
                            <div className="is-user-name"><span>John Doe</span></div>
                            <span className="icon"><i className="mdi mdi-chevron-down"></i></span>
                        </a>
                        <div className="navbar-dropdown">
                            <a href="profile.html" className="navbar-item">
                                <span className="icon"><i className="mdi mdi-account"></i></span>
                                <span>My Profile</span>
                            </a>
                            <a href="#xarosa" className="navbar-item">
                                <span className="icon"><i className="mdi mdi-settings"></i></span>
                                <span>Settings</span>
                            </a>
                            <a href="#xarosa" className="navbar-item">
                                <span className="icon"><i className="mdi mdi-email"></i></span>
                                <span>Messages</span>
                            </a>
                            <hr className="navbar-divider" />
                            <button className="navbar-item" onClick={() => history.push('/auth/login')} >
                                <span className="icon"><i className="mdi mdi-logout"></i></span>
                                <span>Log Out</span>
                            </button>
                        </div>
                    </div>
                    <button title="Log out" className="navbar-item desktop-icon-only" onClick={() => history.push('/auth/login') }>
                        <span className="icon"><i className="mdi mdi-logout"></i></span>
                        <span>Log out</span>
                    </button>
                </div>
            </div>
        </nav>
        );

    }
}


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { authentication, user };
  }
  
  const actionCreators = {
    logout: userActions.logout,
  };
  
  const connectedComponent = connect(mapState, actionCreators)(AdminNavBar);
  export { connectedComponent as AdminNavBar };