import React from "react";

export function FooterSmall(props) {
    return (
        <>
            <footer
                className={
                    (props.absolute
                        ? "absolute w-full bottom-0 bg-emerald-400"
                        : "relative") + " pb-6"
                    }
            >
                <div className="container mx-auto px-4">
                    <hr className="mb-6 border-b-1 border-emerald-200" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4">
                            <div className="text-sm text-emerald-800 font-semibold py-1 text-center md:text-left">
                                Copyright © {new Date().getFullYear()}{" "}
                                <a
                                    href="https://xarosa.online"
                                    className="text-white hover:text-emerald-100 text-sm font-semibold py-1"
                                >
                                    Xarosa Inc.
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
