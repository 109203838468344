import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { userActions } from 'utils/actions';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            email: '',                        
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        
        const { email } = this.state;
        this.props.forgot(email);
    }

    render() {
        const { email } = this.state;

        return (
            <>
            <div className="container mx-auto px-4 h-full">            
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <Link
                            to="/"
                            className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                        >
                            <span className="kai">X</span>-Connect                
                        </Link>
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                <div className="text-blueGray-600 text-center mt-6 mb-3 font-bold">
                                    <h2>Password Reset</h2>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Email
                                        </label>
                                        <input
                                            name="email" value={email} onChange={this.handleChange} 
                                            type="email"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Email"
                                            required
                                        />                                        
                                    </div>
      
                                    <div className="text-center mt-6">
                                        <button
                                            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Send Request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-6 relative">
                            <div className="w-1/2">
                                <a
                                    href="login"                                  
                                    className="text-emerald-400"
                                >
                                    <small>Login</small>
                                </a>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
          </>  
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    forgot: userActions.forgotPassword,    
    logout: userActions.logout,
};

const connectedPage = connect(mapState, actionCreators)(ForgotPassword);
export { connectedPage as ForgotPassword };