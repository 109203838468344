import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import { FooterSmall } from "components";

// pages
import { Login, ForgotPassword, ResetPassword } from "pages/auth";

export function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">          
          <Switch>
            <Route path="/auth/login" exact component={Login} />            
            <Route path="/auth/forgot" exact component={ForgotPassword} />            
            <Route path="/auth/reset" exact component={ResetPassword} />            
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterSmall absolute />
        </section>        
      </main>
    </>
  );
}
