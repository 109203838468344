import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import { store } from 'utils/helpers';
import { App } from './App';

// setup fake backend
import { configureFakeBackend } from 'utils/helpers';
configureFakeBackend();

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);