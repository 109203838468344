import React from 'react'

export class AdminHeroBar extends React.Component {

    
    render() {
        return ( 
            <section className="is-hero-bar">
    <div className="level">
        <div className="level-left">
            <div className="level-item">
                <h1 className="title">
                    Dashboard
                </h1>
            </div>
        </div>
        <div className="level-right">
            <div className="level-item">
                <button className="button light">Button</button>
            </div>
        </div>
    </div>
</section>

        );
    }
}