import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const userActions = {
    login,
    logout,
    getAll, 
    forgotPassword,
    resetPassword,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();    
    return { type: userConstants.LOGOUT };
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}


function forgotPassword(email) {
    /*
    return dispatch => {        
        dispatch(request(email));

        userService.forgotPassword(email)
            .then(
                res => { 
                    dispatch(success());                    
                    dispatch(alertActions.success('Password reset link sent'));                                        
                },
                error => {
                dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
        );
    };

    function request(email) { return { type: userConstants.FORGOT_REQUEST, email } }
    function success(email) { return { type: userConstants.FORGOT_SUCCESS, email } }    
    function failure(error) { return { type: userConstants.FORGOT_FAILURE, error } }
    */

    return { type: userConstants.FORGOT_SUCCESS };

}

function resetPassword(code, password) {
    /*
    return dispatch => {     
        dispatch(request(code));                
        userService.resetPassword(code, password)
            .then(
                data => { 
                    dispatch(success());                    
                    history.push('/login');
                    dispatch(alertActions.success('Passwords reset.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));                    
                }
            );
    };

    function request(code) { return { type: userConstants.RESET_REQUEST, code } }
    function success(data) { return { type: userConstants.RESET_SUCCESS, data } }
    function failure(error) { return { type: userConstants.RESET_FAILURE, error } }    
    */

    return { type: userConstants.RESET_SUCCESS };
}


