import React from 'react'

export class AdminAside extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            show_popup: this.props.show,
            ok : (this.props.okLabel) ? this.props.okLabel : 'OK',            
        }
        
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }
        
    toggleDropdown(e) {
        /* Aside & Navbar: dropdowns */
        if (e.currentTarget.classList.contains('navbar-item')) {
            e.currentTarget.classList.toggle('active');
        } else {
            const dropdownIcon = e.currentTarget.getElementsByClassName('mdi')[1];
            e.currentTarget.parentNode.classList.toggle('active');
            dropdownIcon.classList.toggle('mdi-plus');
            dropdownIcon.classList.toggle('mdi-minus');
        }
    }

    render() {

        return (
        <aside className="aside is-placed-left is-expanded">
            <div className="aside-tools" >
                <a href="/">
                    <div className="text-2xl">
                        <span className="kai">X</span>-Connect
                    </div>
                </a>
            </div>
            <div className="menu is-menu-main">
                <p className="menu-label">General</p>
                <ul className="menu-list">
                    <li className="active">
                        <a href="/admin">
                            <span className="icon"><i className="mdi mdi-desktop-mac"></i></span>
                            <span className="menu-item-label">Dashboard</span>
                        </a>
                    </li>
                </ul>
                <p className="menu-label">Examples</p>
                <ul className="menu-list">
                    <li className="--set-active-tables-html">
                        <a href="tables.html">
                            <span className="icon"><i className="mdi mdi-table"></i></span>
                            <span className="menu-item-label">Tables</span>
                        </a>
                    </li>
                    <li className="--set-active-forms-html">
                        <a href="forms.html">
                            <span className="icon"><i className="mdi mdi-square-edit-outline"></i></span>
                            <span className="menu-item-label">Forms</span>
                        </a>
                    </li>
                    <li className="--set-active-profile-html">
                        <a href="profile.html">
                            <span className="icon"><i className="mdi mdi-account-circle"></i></span>
                            <span className="menu-item-label">Profile</span>
                        </a>
                    </li>
                    <li>
                        <a href="login.html">
                            <span className="icon"><i className="mdi mdi-lock"></i></span>
                            <span className="menu-item-label">Login</span>
                        </a>
                    </li>
                    <li>
                        <a href="#xarosa" className="dropdown" onClick={this.toggleDropdown}>
                            <span className="icon"><i className="mdi mdi-view-list"></i></span>
                            <span className="menu-item-label">Submenus</span>
                            <span className="icon"><i className="mdi mdi-plus"></i></span>
                        </a>
                        <ul>
                            <li>
                                <a href="#void">
                                    <span>Sub-item One</span>
                                </a>
                            </li>
                            <li>
                                <a href="#void">
                                    <span>Sub-item Two</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p className="menu-label">About</p>
                <ul className="menu-list">
                    <li>
                        <a href="/" className="has-icon">
                            <span className="icon"><i className="mdi mdi-help-circle"></i></span>
                            <span className="menu-item-label">Landing</span>
                        </a>
                    </li>                    
                </ul>
            </div>
        </aside>

        )
    }
}