import React from 'react';
import { connect } from 'react-redux';

import { userActions } from 'utils/actions';

import { Navbar, Footer } from "components";

class Landing extends React.Component {
    componentDidMount() {
        this.props.dispatch(userActions.getAll());
    }

    render() {        
        return (                
            <div className="flex flex-col items-stretch justify-start min-h-screen">
                <Navbar />
                <section className="header relative pt-16 items-center flex flex-grow">
                    <div className="container mx-auto items-center flex flex-wrap">
                        <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
                            <div className="pt-32 sm:pt-0">
                                <h2 className="font-semibold text-4xl text-grey-700">
                                    Welcome to <span className="kai">X</span>-Connect
                                </h2>                                            
                            </div>
                        </div>
                    </div>                
                </section>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const connectedHomePage = connect(mapStateToProps)(Landing);
export { connectedHomePage as Landing };