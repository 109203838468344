import React from 'react'

export class AlertPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            show_popup: this.props.show,
            ok : (this.props.okLabel) ? this.props.okLabel : 'OK',            
        }
        
        this.okAction = this.okAction.bind(this);        
        this.closePopup = this.closePopup.bind(this);        
    }

    okAction(e) {
        if (this.props.okAction) this.props.okAction(e);
    }

    closePopup(e) {        
        if (this.props.closePopup) this.props.closePopup();
    }

    render() {

        const { show } = this.props;
        
        const show_popup = (show && show.constructor === Object && Object.keys(show).length !== 0);
        const show_type = show_popup ? show.type : 0;
        

        const title = this.props.msgTitle ? this.props.msgTitle : (show_popup ? show.title : 'Alert');
        const content = this.props.msgContent ? this.props.msgContent : '';

        return (
        <>  
            { show_popup ? 
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">                    
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-blueGray-500 opacity-75"></div>
                    </div>                    
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>                    
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                        { show_type === 'alert-info' && 
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">                            
                            <i className="fas fa-info"></i>                            
                        </div>
                        }
                        { show_type === 'alert-warning' && 
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">                            
                            <i className="fas fa-exclamation-triangle"></i>                            
                        </div>
                        }
                        { show_type === 'alert-danger' && 
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">                            
                            <i className="fas fa-times"></i>                            
                        </div>
                        }
                        { show_type === 'alert-success' && 
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">                            
                            <i className="fas fa-check"></i>                            
                        </div>
                        }
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-blueGray-900" id="modal-headline">
                            {title}
                            </h3>
                            <div className="mt-2">
                            <p className="text-sm text-blueGray-500">
                                {content}
                            </p>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    <div className="bg-blueGray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blueGray-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={this.closePopup}   
                        >
                        OK
                        </button>                        
                    </div>
                    </div>
                </div>
            </div>
            : null }
        </>   
        )    
    }
};
